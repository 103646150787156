import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";

import pages from "../../constants/pages";
import EmptyList from "../misc/EmptyList";
import List from "./audience list components/List";
import { useAxios, useComponent } from "../../hooks";
import Search from "./audience list components/Search";
import { Loader, PageHeader } from "../../components";
import FloatingAddActionButtons from "../../components/FloatingAddActionButton";
import { getAllAudiences } from "./create audience components/audienceObjects";

export default function AudienceList() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [audiences, setAudiences] = useState([]);
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (!searchKey) {
      axios({
        url:
          "/brand/getAllAudiences?brandId=" +
          activeBrand.ID +
          `&page=${page}&limit=10`,
      }).then((response) => {
        if (response.status) {
          if (response.data.audienceData.length > 0) {
            setLastPage({
              totalCount: response.data.totalCounts,
              lastPage: response.data.audienceData.length < 10,
            });

            setAudiences(getAllAudiences(response));
          } else {
            setAudiences([]);
            setLastPage(true);
          }
          loader.stop();
        }
      });
    }
  }, [axios, activeBrand.ID, loader, page, searchKey]);

  const getSearchedAudience = useCallback(
    debounce(async (search, page) => {
      axios({
        url:
          "/brand/getAllAudiences?brandId=" +
          activeBrand.ID +
          `&page=${page}&limit=10` +
          (search ? `&search=${encodeURIComponent(search)}` : ""),
      }).then((response) => {
        if (response.status) {
          if (response.data.audienceData.length > 0) {
            setLastPage({
              totalCount: response.data.totalCounts,
              lastPage: response.data.audienceData.length < 10,
            });

            setAudiences(getAllAudiences(response));
          } else {
            setAudiences([]);
            setLastPage(true);
          }
          loader.stop();
        }
      });
    }, 500), // Adjust debounce delay as needed
    []
  );

  useEffect(() => {
    if (searchKey.trim() !== "") {
      getSearchedAudience(searchKey, page);
    }
  }, [searchKey, page, getSearchedAudience]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"My Audiences"}
            pageHeader="List of Audiences"
            icons={[
              <Search
                getSearchedAudience={getSearchedAudience}
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
          />
        </Grid>
      </Grid>

      <FloatingAddActionButtons
        route={pages.createAudience.route}
        title={"Add Audience"}
      />

      <Loader height="75%">
        {audiences.length > 0 ? (
          <List audiences={audiences} />
        ) : (
          <EmptyList
            title={"No audience found."}
            button={"Create Audience"}
            route={pages.createAudience.route}
          />
        )}
      </Loader>

      {lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            onClick={() => handlePagination("back")}
            disabled={page < 2}
          >
            <ArrowBack />
          </Button>

          {Array(Math.ceil(lastPage.totalCount / 10))
            .fill(0)
            .map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="h5"
                  alignContent={"center"}
                  sx={{
                    mx: 1,
                    p: "10px",
                    borderRadius: "10px",
                    ...(index + 1 === page
                      ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                      : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                  }}
                  onClick={() => handlePagination("index", index)}
                >
                  {index + 1}
                </Typography>
              );
            })}

          <Button
            variant="outlined"
            onClick={() => handlePagination("next")}
            disabled={page === Math.ceil(lastPage.totalCount / 10)}
          >
            <ArrowForward />
          </Button>
        </Grid>
      )}
    </Fragment>
  );
}
