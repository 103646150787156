import { Fragment } from "react";
import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { Label, TextField, AutocompleteMultiSelect } from "../../../components";

export default function FormTwo({ list, formikProps, editState }) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <AutocompleteMultiSelect
          required
          name="siteCategory"
          label={"Business Category"}
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={list?.categories}
          placeholder="Select Category"
          onChange={(_, arr, reason, option) => {
            handleChange(arr, option, reason, "siteCategory", "name");
          }}
        />
      </Grid>

      {formikProps.values.siteCategory.includes("Others") && (
        <Fragment>
          <Grid item xs={6}>
            <TextField
              required
              disabled={!editState}
              formikProps={formikProps}
              name="others_category"
              label={"Enter your other category"}
              placeholder={"Enter your other category"}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          required
          name="audienceLocation"
          label={"Audience Location"}
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={list?.locations}
          placeholder="Select Location"
          onChange={(_, arr, reason, option) =>
            handleChange(
              arr,
              option,
              reason,
              "audienceLocation",
              "country_code"
            )
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          required
          label={"Audience Language"}
          name="audienceLanguages"
          disabled={!editState}
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={list?.languages}
          placeholder="Select Language"
          onChange={(_, arr, reason, option) =>
            handleChange(arr, option, reason, "audienceLanguages", "code")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          label={"Facebook Handle"}
          name="socialMedia.facebook"
          placeholder={"Enter your facebook handle"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.facebook &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://facebook.com/" +
                        formikProps.values.socialMedia.facebook.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.instagram"
          label={"Instagram Handle"}
          placeholder={"Enter your instagram handle"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.instagram &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://instagram.com/" +
                        formikProps.values.socialMedia.instagram.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.twitter"
          label={"Twitter Handle"}
          placeholder={"Enter your twitter handle"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.twitter &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://twitter.com/" +
                        formikProps.values.socialMedia.twitter.replace(
                          /(?:https?|ftp):\/\/[\n\S]+/g,
                          ""
                        )
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled={!editState}
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          label={"Whatsapp Number"}
          placeholder={"Enter your whatsapp number"}
          InputProps={{
            endAdornment: !!formikProps.values.socialMedia.whatsapp &&
              !editState && (
                <IconButton
                  onClick={() =>
                    linkOpen(
                      "https://wa.me/" + formikProps.values.socialMedia.whatsapp
                    )
                  }
                >
                  <TbExternalLink />
                </IconButton>
              ),
          }}
        />
      </Grid>
    </Fragment>
  );

  function handleChange(arr, { option }, reason, name, displayName) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [name]: setValuesOfDropDown(prevVal[name]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val];

      if (reason === "removeOption") {
        const nameToDropDown = {
          audienceLocation: "country_code",
          siteCategory: "name",
          audienceLanguages: "code",
        };

        let dropDownName = nameToDropDown[name];

        if (option[dropDownName]) {
          values = [
            ...values.filter((items) => items !== option[dropDownName]),
          ];
        } else {
          values.pop();
        }
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
      }

      return values;
    }
  }

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
