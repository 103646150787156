import { Fragment } from "react";
import styled from "styled-components/macro";
import { DeleteOutline } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import { colors } from "../../css components/Style";
import { ComponentHeader, Label, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  color: #02a0fc;
  font-size: 20px;
  font-weight: 600;
`;

export default function FormThree({ formikProps, editState }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Additional Field"}
          subtitle={"Not able to find desired audience field? Add them."}
          buttons={[
            <Button
              onClick={handleAddFields}
              variant="contained"
              disabled={!editState}
            >
              Add Additional Field
            </Button>,
          ]}
        />
      </Grid>

      {/* <Grid item xs={6}>
        <MainHeading>Additional Fields</MainHeading>
      </Grid>

      {editState && (
        <Grid item xs={6} textAlign={"end"}>
        
        </Grid>
      )} */}

      {formikProps.values.additionalFields.map((_, index) => (
        <Fragment>
          <Grid item xs={10}>
            <Label title={"Additional Field " + (index + 1)} />
          </Grid>

          <Grid item xs={5}>
            <TextField
              disabled={!editState}
              formikProps={formikProps}
              name={"additionalFields[" + index + "].key"}
              placeholder="key"
              label="Key"
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              disabled={!editState}
              formikProps={formikProps}
              name={"additionalFields[" + index + "].value"}
              placeholder="Value"
              label="Value"
            />
          </Grid>

          {editState && (
            <Grid item xs={2} alignSelf={"center"}>
              <Tooltip title={"Delete additional field" + index}>
                <IconButton
                  style={{ color: colors.error }}
                  onClick={() => handleDelete(index)}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Fragment>
      ))}
    </Fragment>
  );

  function handleDelete(index) {
    let fields = [...formikProps.values.additionalFields];
    fields.splice(index, 1);

    formikProps.setValues((prev) => ({
      ...prev,
      additionalFields: fields,
    }));
  }

  function handleAddFields() {
    formikProps.setValues((prev) => ({
      ...prev,
      additionalFields: setAddFields(prev.additionalFields),
    }));

    function setAddFields(fields) {
      fields.push({
        key: "",
        value: "",
      });

      return fields;
    }
  }
}
