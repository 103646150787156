import queryString from "query-string";
import React, { useEffect, useState } from "react";

import { useAxios, useComponent } from "../../hooks";
import Form from "./agency details components/Form";
import {
  agencyObject,
  objectFromResponse,
} from "./agency details components/agencyObject";
import { Loader } from "../../components";

export default function AgencyDetails() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(agencyObject);

  const agencyID = queryString.parse(window.location.search).agencyID;

  useEffect(() => {
    if (!agencyID) {
      setEditState(true);
    }
  }, [agencyID]);

  useEffect(() => {
    loader.start();

    if (!!agencyID) {
      axios({
        url: "/getAgency/" + agencyID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponse(response.data));
          loader.stop();
        }
      });
    } else {
      loader.stop();
    }
  }, [agencyID, axios, loader]);

  return (
    <Loader>
      <Form
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        setFormData={setFormData}
      />
    </Loader>
  );
}
