import { Formik } from "formik";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { signInMember } from "../../../redux/slices/memberSlice";
import {
  logInDetailsObject,
  setAgencyData,
  setBrandData,
  validationSchema,
} from "./logInDetailsObject";

export default function Form() {
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const axios = useAxios({ disableAuthToken: true });

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={logInDetailsObject}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/auth/login",
            method: "POST",
            disableRedirect: true,
            data: {
              email: formData.email,
              password: formData.password,
            },
          })
            .then(async (response) => {
              if (response.status) {
                const data = response.data;

                const timeStamp = new Date(data?.expiresIn).getTime();
                localStorage.setItem("expirySession", timeStamp);

                if (data.user[0]?.is_parent_brand_verified === 2) {
                  dispatch(
                    alert({
                      type: "warning",
                      message:
                        "Your brand has been rejected by the admin. Please contact the admin for further details.",
                    })
                  );
                  setSubmitting(false);
                  return;
                }

                if (data.user[0]?.role_name === "NORMAL_USER") {
                  sessionStorage.setItem("ladder", JSON.stringify(data?.steps));
                  sessionStorage.setItem(
                    "userID",
                    parseInt(data.user[0]?.user_id)
                  );
                  sessionStorage.setItem("authToken", data.token);
                  sessionStorage.setItem(
                    "businessEntityId",
                    data.user[0]?.business_entity_id
                  );

                  sessionStorage.setItem(
                    "brandID",
                    response.data.brands[0]?.brand_id
                  );
                  sessionStorage.setItem("role", data.user[0]?.role_name);

                  if (!data.steps.step2 || !data.steps.step3) {
                    navigate(pages.createWelcomePage.route);
                  }

                  if (data.steps.step2 && data.steps.step3) {
                    if (data.user[0]?.is_parent_brand_verified === 0) {
                      navigate(pages.createWelcomePage.route);
                    } else if (!data.user[0]?.is_admin_verified) {
                      navigate(pages.createWelcomePage.route);
                    } else {
                      navigate(pages.welcomePage.route);
                    }
                  }
                }

                if (data.user[0]?.role_name === "AGENCY") {
                  sessionStorage.setItem("ladder", JSON.stringify(data?.steps));
                  sessionStorage.setItem(
                    "userID",
                    parseInt(data.user[0]?.user_id)
                  );
                  sessionStorage.setItem("authToken", data.token);
                  sessionStorage.setItem(
                    "businessEntityId",
                    data.user[0]?.business_entity_id
                  );

                  sessionStorage.setItem(
                    "agencyID",
                    data?.agency[0]?.agency_id
                  );
                  sessionStorage.setItem("role", data.user[0]?.role_name);

                  if (!data.steps.step2 || !data.steps.step3) {
                    navigate(pages.welcomeAsAgencyPage.route);
                  }

                  if (data.steps.step2 && data.steps.step3) {
                    if (!data.user[0]?.is_admin_verified) {
                      navigate(pages.welcomeAsAgencyPage.route);
                    } else if (data.brands.length === 0) {
                      navigate(pages.brandDetailCreateEmpty.route);
                    } else {
                      navigate(pages.welcomePage.route);
                    }
                  }
                }

                if (data.user[0]?.role_name === "SUPER_ADMIN") {
                  sessionStorage.setItem("role", data.user[0]?.role_name);
                  await dispatch(
                    signInMember({
                      authToken: data.token,
                      userID: parseInt(data.user[0]?.user_id),
                      businessEntityId: parseInt(
                        data.user[0]?.business_entity_id
                      ),
                    })
                  );
                  navigate(pages.accountVerification.route);
                }

                //create new brand or company

                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
              } else {
                dispatch(
                  alert({
                    type: "error",
                    message: response.message,
                  })
                );
              }

              setSubmitting(false);
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <FormDisplay formikProps={formikProps} />
          </form>
        )}
      </Formik>
    </Fragment>
  );
}
