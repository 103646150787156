const pages = {
  //Dashboard Module

  signIn: {
    ID: "sign-in",
    route: "/sign-in",
  },

  signUp: {
    ID: "sign-up",
    route: "/sign-up",
  },

  analytics: {
    ID: "analytics",
    route: "/analytics/",
  },

  home: {
    ID: "home",
    route: "/",
  },

  verifyAccount: {
    ID: "verify-account",
    route: "/verify/",
  },

  brandDetailCreateEmpty: {
    ID: "create-brand-empty",
    route: "/create-brand-details",
  },

  brandDetailEmpty: {
    ID: "create-brand-empty-details",
    route: "/brand-details",
  },

  brandDetails: {
    ID: "brand-details",
    route: "/brand-details-page",
  },

  companyDetails: {
    ID: "company-details",
    route: "/company-details-page",
  },

  companyDetailsCreateEmpty: {
    ID: "company-details-create-empty",
    route: "/create-company-details",
  },

  companyDetailsEmpty: {
    ID: "company-details-empty",
    route: "/company-details",
  },

  myCampaign: {
    ID: "my-campaign",
    route: "/all-campaign",
  },

  myBilling: {
    ID: "my-billings",
    route: "/all-billings",
  },

  billingReport: {
    ID: "billing-report",
    route: "/billing-report",
  },

  billingDetails: {
    ID: "billing-details",
    route: "/billing-details",
  },

  campaignRequest: {
    ID: "dp-campaign",
    route: "/campaigns",
  },

  createCampaign: {
    ID: "create-campaign",
    route: "/create-campaign",
  },

  campaignDetails: {
    ID: "campaign-details",
    route: "/campaign-details/",
  },

  campaignRequestDetails: {
    ID: "campaign-request-details",
    route: "/campaign-request-details/",
  },

  campaignAccepted: {
    ID: "campaign-accepted",
    route: "/campaign-accepted",
  },

  campaignAcceptedDetails: {
    ID: "campaign-accepted-details",
    route: "/campaign-accepted-details/",
  },

  audienceList: {
    ID: "audience-list",
    route: "/all-audience",
  },

  createAudience: {
    ID: "create-audience",
    route: "/create-audience",
  },

  audienceDetails: {
    ID: "audience-details",
    route: "/audience-details/",
  },

  exploreConnections: {
    ID: "explore-connections",
    route: "/my-connections",
  },

  connectionDetails: {
    ID: "connection-details",
    route: "/connection-details/",
  },

  welcomePage: {
    ID: "welcome-page",
    route: "/welcome-page",
  },

  createWelcomePage: {
    ID: "create-welcome-page",
    route: "/setup-brand-page",
  },

  createTemplate: {
    ID: "create-template",
    route: "/create-template",
  },

  templateList: {
    ID: "template-list",
    route: "/template-list",
  },

  templateDetails: {
    ID: "template-details",
    route: "/template-details/",
  },

  creditPage: {
    ID: "credit-page",
    route: "/credit-page",
  },

  profilePage: {
    ID: "profile-page",
    route: "/profile-page",
  },

  // super admin pages

  brandsList: {
    ID: "administrator-brands",
    route: "/brands",
  },

  usersList: {
    ID: "users-list",
    route: "/users/",
  },

  accountVerification: {
    ID: "verify-account",
    route: "/accounts-verification",
  },

  createUser: {
    ID: "create-user",
    route: "/create-user",
  },

  userDetails: {
    ID: "user-details",
    route: "/user-details/",
  },

  superAdminBrandCampaigns: {
    ID: "brand-campaigns",
    route: "/brand-campaigns/",
  },

  addCampaignStats: {
    ID: "add-campaign-stats",
    route: "/add-campaign-stats/",
  },

  superAdminCampaignDetails: {
    ID: "brand-campaign-details",
    route: "/brand-campaign-details/",
  },

  superAdminAllCampaigns: {
    ID: "all-campaign",
    route: "/campaigns-all",
  },

  dpOnBoardingList: {
    ID: "all-boarding",
    route: "/on-boarded-connections",
  },

  dpOnBoarding: {
    ID: "dp-on-boarding",
    route: "/dp-on-boarding",
  },

  dpOnBoardingDetails: {
    ID: "dp-on-boarding",
    route: "/dp-on-boarding-details/",
  },

  changePassword: {
    ID: "change-password",
    route: "/change-password",
  },

  resetEmailPasswordPage: {
    ID: "reset-email-password",
    route: "/reset-password",
  },

  forgotPasswordPage: {
    ID: "forgot-password",
    route: "/forgot-password/",
  },

  unverifiedAgenciesList: {
    ID: "unverified-agencies-list",
    route: "/superadmin-agencies-list",
  },

  unverifiedAgenciesDetails: {
    ID: "unverified-agencies-details",
    route: "/superadmin-agencies-details",
  },

  unverifiedCompanyAgencyDetails: {
    ID: "unverified-agencies-details",
    route: "/superadmin-agency-company-details",
  },

  unverifiedPage: {
    ID: "unverified-brand-list",
    route: "/superadmin-brand-list",
  },

  unverifiedPageDetails: {
    ID: "unverified-brand-details",
    route: "/superadmin-brand-details",
  },

  unverifiedCompanyDetails: {
    ID: "unverified-company-details",
    route: "/superadmin-company-details",
  },

  permissionSettingsPage: {
    ID: "permission-settings",
    route: "/permission-settings",
  },

  dynamicUiPageDetails: {
    ID: "site-settings",
    route: "/site-settings",
  },

  //AGENCY

  welcomeAsAgencyPage: {
    ID: "welcome-agency",
    route: "/welcome-agency",
  },

  createAgencyPage: {
    ID: "create-agency",
    route: "/create-agency",
  },

  agencyDetailsEmptyPage: {
    ID: "agency-details-empty",
    route: "/agency-details",
  },

  //misc
  permissionDenied: {
    ID: "permission",
    route: "/permission-denied",
  },

  whatsAppOnboardingPage: {
    ID: "whatsapp-onboarding",
    route: "/whatsapp-onboarding",
  },

  createOnboardLink: {
    ID: "createOnboardLink",
    route: "/create-onboard-link/",
  },

  botDetailsPage: {
    ID: "botDetailsPage",
    route: "/bot-details/",
  },
};

export default pages;
