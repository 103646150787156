import * as Yup from "yup";

export const onboardingObject = {
  connectionID: "",
  brandID: "",
  brandName: "",
  connectionUrl: "",
  port: "",
  token: "",
  fields: [],
  mappedFields: [],
  fieldNames: "",
  connection: false,
  csvUpload: false,
};

export function objectFromFormData(formData, mappedFields) {
  let fieldMappings = {};
  for (let field of formData.fields) {
    if (field === undefined) {
      continue;
    }
    if (
      field !== undefined &&
      Object.values(field)[0] !== "" &&
      mappedFields[Object.keys(field)[0]] !== ""
    ) {
      fieldMappings[Object.keys(field)[0]] = Object.values(field)[0];
    } else if (
      field !== undefined &&
      Object.values(field)[0] === "" &&
      mappedFields[Object.keys(field)[0]] !== "" &&
      mappedFields[Object.keys(field)[0]] !== undefined
    ) {
      fieldMappings[Object.keys(field)[0]] = null;
    }
  }

  return {
    brand_id: formData.brandID,
    connectionDetails: {
      connection_url: formData.connectionUrl,
      port: formData.port,
      token: formData.token,
      csv_upload: formData.csvUpload,
    },
    fieldMappings: fieldMappings,
  };
}

export function objectFromResponse(responseData, fieldsMapped) {
  let values = {};
  let fields = [];
  if (responseData.fieldMappings.length > 0) {
    for (let field of responseData.fieldMappings) {
      values[field.field_value] = {
        value: field.field_name,
        type: field.field_type,
      };
    }

    for (let field of fieldsMapped) {
      if (values[field.field_name] === undefined) {
        fields.push({
          [field.field_name]: {
            value: "",
            type: "",
          },
        });
      } else {
        fields.push({
          [field.field_name]: {
            value: values[field.field_name].value,
            type: values[field.field_name].type,
          },
        });
      }
    }
  }
  return {
    fields: fields,
    mappedFields: values,
    brandDetails: responseData.brandDetails,
    port: responseData.connectionDetails.port,
    fieldNames: responseData.fieldsNames || "",
    connection: responseData.connection || false,
    token: responseData.connectionDetails?.token,
    csvUpload: responseData.brandDetails?.csv_upload || false,
    brandID: responseData.connectionDetails?.brand_id,
    brandName: responseData.brandDetails?.brand_name,
    connectionID: responseData.connectionDetails?.connection_id,
    connectionUrl: responseData.connectionDetails?.connection_url,
  };
}

export const validationSchema = Yup.object().shape({
  brandID: Yup.string().required("Brand name is required"),
  csvUpload: Yup.boolean(),
  connectionUrl: Yup.string().when("csvUpload", {
    is: (value) => value === false,
    then: Yup.string()
      .matches(/((https?):\/\/)\d+(\.\d+)*$/, "Enter correct url!")
      .required("IP/Domain Url is required"),
  }),

  port: Yup.string().when("csvUpload", {
    is: (value) => value === false,
    then: Yup.string().required("Port is required"),
  }),

  token: Yup.string().when("csvUpload", {
    is: (value) => value === false,
    then: Yup.string().required("Port is required"),
  }),
});
