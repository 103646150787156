import { create } from "jss";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import jssPreset from "@mui/styles/jssPreset";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Alert, Snackbar, Slide } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components/macro";
import StylesProvider from "@mui/styles/StylesProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import createTheme from "./theme";
import Router from "./router/Router";
import "./pages/welcome page components/style.css";
import "./pages/css components/style.css";
import { closeAlert } from "./redux/slices/alertSlice";
import { ThemeContext } from "./components/ThemeProvider";
import AuthVerification from "./components/AuthVerification";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

// const history = createBrowserHistory({ basename: "/v1" });

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alertDetails);

  const { theme } = useContext(ThemeContext);
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Verismart"
        defaultTitle="ADN-Verismart Dashboard"
      />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                {alert.multiple ? (
                  alert.multiple.map((msg) => (
                    <div style={{ display: "none" }}>
                      {enqueueSnackbar(msg, {
                        variant: alert.type,
                        autoHideDuration: 4000,
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                      })}
                    </div>
                  ))
                ) : (
                  <Snackbar
                    open={alert.state}
                    autoHideDuration={4000}
                    onClose={handleAlertClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    style={{ top: "8px", right: "10px" }}
                    TransitionComponent={(props) => (
                      <Slide
                        {...props}
                        in={alert.state}
                        direction="right"
                        unmountOnExit
                      />
                    )}
                  >
                    <Alert
                      variant="filled"
                      severity={alert.type}
                      onClose={handleAlertClose}
                      style={{ minWidth: "350px" }}
                    >
                      {alert.message}
                    </Alert>
                  </Snackbar>
                )}

                <Router />
                <AuthVerification />
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );

  function handleAlertClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  }
}

export default App;
