import { Fragment } from "react";
import { TbExternalLink } from "react-icons/tb";
import { Grid, IconButton } from "@mui/material";

import { TextField } from "../../../components";

export default function FormOne({ formData }) {
  return (
    <Fragment>
      <Grid item xs={6}>
        <TextField
          size="small"
          multiline
          name="siteCategory"
          label="Site Category"
          disabled
          value={formData.siteCategory.map((cat) => cat.name).join(",")}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          multiline
          name="audienceLocation"
          label="Location"
          disabled
          value={formData.audienceLocation
            .map((loc) => loc.country_name)
            .join(",")}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          multiline
          name="audienceLanguages"
          disabled
          label="Language"
          value={formData.audienceLanguages
            .map((lan) => lan.language)
            .join(",")}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          size="small"
          name="socialMedia.facebook"
          // inputProps={{ "aria-label": "Without label" }}
          label="Facebook URL"
          value={formData.socialMedia.facebook}
          InputProps={{
            endAdornment: !!formData.socialMedia.facebook && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://facebook.com/" +
                      !!formData.socialMedia.facebook.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          size="small"
          name="socialMedia.instagram"
          label="Instagram URL"
          value={formData.socialMedia.instagram}
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.socialMedia.instagram && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://instagram.com/" +
                      !!formData.socialMedia.instagram.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          size="small"
          name="socialMedia.twitter"
          label="Twitter URL"
          value={formData.socialMedia.twitter}
          // inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            endAdornment: !!formData.socialMedia.twitter && (
              <IconButton
                onClick={() =>
                  linkOpen(
                    "https://twitter.com/" +
                      !!formData.socialMedia.twitter.replace(
                        /(?:https?|ftp):\/\/[\n\S]+/g,
                        ""
                      )
                  )
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          size="small"
          value={formData.socialMedia.whatsapp}
          label="Whatsapp Number"
          name="socialMedia.whatsapp"
          InputProps={{
            endAdornment: !!formData.socialMedia.whatsapp && (
              <IconButton
                onClick={() =>
                  linkOpen("https://wa.me/" + !!formData.socialMedia.whatsapp)
                }
              >
                <TbExternalLink />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Fragment>
  );

  function linkOpen(val) {
    window.open(val, "_blank");
  }
}
