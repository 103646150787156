import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import signIn from "./helper functions/signIn";
import signOut from "./helper functions/signOut";

export const signInMember = createAsyncThunk("member/signIn", signIn);

export const signOutMember = createAsyncThunk("member/signOutMember", signOut);

const memberInitialState = {
  authToken: "",
  userID: "",
  businessEntityID: "",
  brands: [],
  agencies: [],
  dashboardState: "",
  steps: false,
  creditDetails: {},
  userData: {
    email: "",
    givenName: "",
    familyName: "",
    displayName: "",
    role: "",
  },
  activeAgency: {
    ID: "",
    about: "",
    agencyName: "",
    logo: "",
    website: "",
    socialMedia: {},
  },
  activeBrand: {
    ID: "",
    about: "",
    brandName: "",
    logo: "",
    languages: [],
    locations: [],
    category: [],
    mau: "",
    onlineStore: "",
    website: "",
    socialMedia: {},
  },
};

export const memberSlice = createSlice({
  name: "member",
  initialState: memberInitialState,
  reducers: {
    setActiveBrand(state, { payload }) {
      state.activeBrand.ID = parseInt(payload.brand_id);
      state.activeBrand.about = payload.about;
      state.activeBrand.brandName = payload.brand_name;
      state.activeBrand.logo = payload.logo;
      state.activeBrand.languages = payload.languages;
      state.activeBrand.locations = payload.audience_location;
      state.activeBrand.category = payload.site_category;
      state.activeBrand.mau = payload.mau;
      state.activeBrand.onlineStore = payload.online_store;
      state.activeBrand.website = payload.website;
      state.activeBrand.socialMedia = payload.social_media_handles;
    },

    setActiveAgency(state, { payload }) {
      state.activeAgency.ID = payload.agency_id;
      state.activeAgency.agencyName = payload.agency_name;
      state.activeAgency.about = payload.about;
      state.activeAgency.logo = payload.agencyLogo;
      state.activeAgency.website = payload.website;
    },

    setBrands(state, { payload }) {
      state.brands.push(payload);
    },

    setDashboardState(state, { payload }) {
      state.dashboardState = payload;
    },

    deleteAccessToken: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return memberInitialState;
    },
  },

  extraReducers: {
    [signInMember.fulfilled]: (_, { payload }) => {
      return payload;
    },

    [signInMember.rejected]: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");

      return { ...memberInitialState, authToken: "" };
    },

    [signOutMember.fulfilled]: () => {
      localStorage.removeItem("authToken");
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("brandType");
      sessionStorage.removeItem("activeBrand");
      sessionStorage.removeItem("businessEntityId");
      sessionStorage.removeItem("userID");
      sessionStorage.removeItem("brandID");
      sessionStorage.removeItem("ladder");
      sessionStorage.removeItem("expirySession");
      localStorage.removeItem(
        "dashboardState" + sessionStorage.getItem("activeBrand")
      );

      return memberInitialState;
    },
  },
});

export const {
  setDashboardState,
  deleteAccessToken,
  setBrands,
  setActiveBrand,
} = memberSlice.actions;

export default memberSlice.reducer;
