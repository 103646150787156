import { Fragment, useState } from "react";
import { Navigation } from "swiper";
import { Grid, MenuItem, Typography } from "@mui/material";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import pages from "../../../constants/pages";
import { TextField } from "../../../components";
import { signInMember, signOutMember } from "../../../redux/slices/memberSlice";
import {
  CancelButton,
  PrimaryButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import { colors } from "../../css components/Style";

const Image = styled.div`
  margin-top: 20px;
  height: 180px;
  width: 180px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

export default function SelectRole({
  businessEntityID,
  brands,
  authToken,
  userID,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role =
    useSelector((state) => state?.memberDetails.role) ||
    sessionStorage.getItem("role");

  const { dashboardState, brandType, activeBrand } = useSelector(
    (state) => state.memberDetails
  );

  const [currentBrand, setCurrentBrand] = useState({
    ID: activeBrand.ID,
    name: activeBrand.name,
    type: activeBrand.brandType || brandType,
  });

  const [userDashboardState, setUserDashboardState] = useState(
    dashboardState || "monetization"
  );

  return (
    <Fragment>
      <div className="outer-brand-div">
        <div className="select-brand-div">
          <Swiper
            slidesPerView={3}
            spaceBetween={1}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {brands.map((brand) => (
              <Fragment>
                <SwiperSlide>
                  <Image
                    style={{
                      opacity:
                        (brand.isBrandVerified === 0 ||
                          brand.isBrandVerified === 2) &&
                        0.4,
                      pointerEvents:
                        (brand.isBrandVerified === 0 ||
                          brand.isBrandVerified === 2) &&
                        "none",
                    }}
                    onClick={() =>
                      setBrand(brand.ID, brand.brandName, brand.brandType)
                    }
                  >
                    <img
                      className="img-logo"
                      style={{
                        cursor: "pointer",
                        border:
                          currentBrand.ID === brand.ID &&
                          `1px solid ${colors.purple} `,
                      }}
                      src={brand.logo}
                      alt="logo"
                    />
                    <Typography className="brand-name">
                      {brand.brandName}
                    </Typography>
                  </Image>
                </SwiperSlide>
              </Fragment>
            ))}
          </Swiper>

          <div className="choose-ds">
            <TextField
              size="small"
              select
              placeholder="Select Role"
              name="role"
              value={userDashboardState}
              onChange={setDashboardUser}
              disabled={currentBrand.type !== 3}
            >
              <MenuItem value={`promotion`}>I am a Promotion Partner</MenuItem>
              <MenuItem value={`monetization`}>
                I am a Monetization Partner
              </MenuItem>
            </TextField>
          </div>
        </div>
      </div>

      <div className="bottom-div">
        <div>
          <CancelButton
            style={{ float: "left" }}
            variant="outlined"
            onClick={() => dispatch(signOutMember())}
          >
            Logout
          </CancelButton>
        </div>

        <div style={{ gap: 5 }}>
          {role === "AGENCY" && (
            <PrimaryButton
              style={{ float: "left", marginRight: "20px" }}
              variant="outlined"
              onClick={() => addNewBrand()}
            >
              Add New Brand
            </PrimaryButton>
          )}

          <PrimaryLoadingButton
            style={{ float: "right" }}
            type="submit"
            variant="contained"
            disabled={!Boolean(currentBrand.ID)}
            onClick={async () => {
              localStorage.setItem(
                "dashboardState" + currentBrand.ID,
                userDashboardState
              );
              await dispatch(
                signInMember({
                  authToken: authToken,
                  userID: userID,
                  businessEntityId: businessEntityID,
                  brandType: currentBrand.type,
                })
              );

              navigate(pages.brandDetails.route);
            }}
          >
            Let's Go
          </PrimaryLoadingButton>
        </div>
      </div>
    </Fragment>
  );

  async function addNewBrand() {
    localStorage.setItem("dashboardState" + currentBrand.ID, "monetization");
    navigate(pages.brandDetailCreateEmpty.route + "?newMultiBrand=" + true);
  }

  function setBrand(ID, name, type) {
    if (type === 2) {
      setUserDashboardState("monetization");
    }
    if (type === 3) {
      setUserDashboardState("promotion");
    }

    setCurrentBrand({
      ID: ID,
      name: name,
      type: type,
    });
    sessionStorage.setItem("activeBrand", ID);
    sessionStorage.setItem("brandType", type);
  }

  function setDashboardUser(e) {
    setUserDashboardState(e.target.value);
  }
}
