import { Fragment, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useAxios } from "../../hooks";
import EmptyList from "../misc/EmptyList";
import { colors } from "../css components/Style";
import List from "./unverified list components/List";
import { Loader, PageHeader } from "../../components";
import SortBy from "./unverified list components/SortBy";
import Search from "./unverified list components/Search";
import VerifiedList from "./unverified list components/VerifiedList";

export default function UnverifiedBrandList() {
  const axios = useAxios();

  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [brandStatus, setBrandStatus] = useState();
  const [lastPage, setLastPage] = useState({
    totalCount: 0,
    lastPage: false,
  });

  let sortList = [
    { value: "", label: "ALL", color: colors.blue },
    { value: 1, label: "Verified", color: colors.green },
    { value: 0, label: "Unverified", color: colors.yellow },
    { value: 2, label: "Rejected", color: colors.error },
  ];

  useEffect(() => {
    axios({
      url:
        "/getAllBrands?page=" +
        page +
        "&limit=10" +
        (sortBy !== "" ? "&is_brand_verified=" + sortBy : "") +
        (Boolean(searchKey) ? "&search=" + encodeURIComponent(searchKey) : ""),
      method: "GET",
      disableRedirect: false,
    }).then((response) => {
      if (response.status) {
        setBrands(response.data.parsedBrands);

        setLastPage({
          totalCount: response.data.totalCount,
          lastPage: response.data.parsedBrands.length < 10,
        });
      } else {
        setBrands([]);

        setLastPage({
          totalCount: response.data?.totalCount || 0,
          lastPage: false,
        });
      }
    });
  }, [axios, brandStatus, page, sortBy, searchKey]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            icons={[
              <SortBy
                sortList={sortList}
                sortBy={sortBy}
                setPage={setPage}
                setSortBy={setSortBy}
              />,
              <Search
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
            pageName={"Brands"}
            pageHeader={"List of brands"}
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {brands.length > 0 ? (
          sortBy === 1 ? (
            <VerifiedList brandList={brands} />
          ) : (
            <List brands={brands} setBrandStatus={setBrandStatus} />
          )
        ) : (
          <EmptyList title={"No new brands"} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            onClick={() => handlePagination("back")}
            disabled={page < 2}
          >
            <ArrowBackIcon />
          </Button>

          {Array(Math.ceil(lastPage.totalCount / 10))
            .fill(0)
            .map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="h5"
                  alignContent={"center"}
                  sx={{
                    mx: 1,
                    p: "10px",
                    borderRadius: "10px",
                    ...(index + 1 === page
                      ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                      : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                  }}
                  onClick={() => handlePagination("index", index)}
                >
                  {index + 1}
                </Typography>
              );
            })}

          <Button
            variant="outlined"
            onClick={() => handlePagination("next")}
            disabled={lastPage.lastPage}
          >
            <ArrowForwardIcon />
          </Button>
        </Grid>
      )}
    </Fragment>
  );
}
