import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Accept, CancelButton } from "../pages/css components/Button";

export default function EditButtons({ formikProps, editState, setEditState }) {
  const navigate = useNavigate();

  function handleCancel() {
    navigate(-1);
  }

  return (
    <Fragment>
      <CancelButton variant="outlined" onClick={handleCancel}>
        Back
      </CancelButton>

      {!editState && formikProps?.values?.status !== "ACTIVE" && (
        <Accept
          style={{ marginLeft: "5px" }}
          size="large"
          variant="contained"
          onClick={() => setEditState(!editState)}
        >
          Edit
        </Accept>
      )}
    </Fragment>
  );
}
