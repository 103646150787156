import { Button, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState, useEffect, Fragment } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import EmptyList from "../misc/EmptyList";
import SortBy from "./brand list components/Sort";
import Search from "./brand list components/Search";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import BrandList from "./brand list components/BrandList";
import Categorize from "./brand list components/Categorize";

export default function SuperAdminBrand() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState();
  const [sortBy, setSortBy] = useState("ALL");
  const [categorize, setCategorize] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [searchBrands, setSearchBrands] = useState();
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  useEffect(() => {
    loader.start();

    let url = "/getAllBrands" + `?page=${page}&limit=10`;

    if (brands) {
      url = url + "&brand_type=" + brands;
    }

    if (searchBrands) {
      url = url + "&search=" + encodeURIComponent(searchBrands);
    }

    axios({
      url: url,
    }).then((response) => {
      if (response.status) {
        if (response.data.parsedBrands.length > 0) {
          setBrandList(response.data.parsedBrands);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: response.data.parsedBrands.length < 10,
          });
        } else {
          setBrandList(response.data);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: true,
          });
        }

        loader.apiComplete();
      }
    });
  }, [loader, axios, brands, searchBrands, page]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"All Brands"}
            pageHeader={"View Users by brands"}
            icons={[
              <Categorize
                categorize={categorize}
                setCategorize={setCategorize}
                brands={brands}
                setPage={setPage}
                setBrands={setBrands}
                setSearchBrands={setSearchBrands}
              />,

              <Search
                setPage={setPage}
                setBrands={setBrands}
                setSearchBrands={setSearchBrands}
              />,
            ]}
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {brandList.length > 0 ? (
          <BrandList brandList={brandList} component={"users"} />
        ) : (
          <EmptyList title={"No new brand"} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            onClick={() => handlePagination("back")}
            disabled={page < 2}
          >
            <ArrowBackIcon />
          </Button>

          {Array(Math.ceil(lastPage.totalCount / 10))
            .fill(0)
            .map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="h5"
                  alignContent={"center"}
                  sx={{
                    mx: 1,
                    p: "10px",
                    borderRadius: "10px",
                    ...(index + 1 === page
                      ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                      : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                  }}
                  onClick={() => handlePagination("index", index)}
                >
                  {index + 1}
                </Typography>
              );
            })}

          <Button
            variant="outlined"
            onClick={() => handlePagination("next")}
            disabled={lastPage.lastPage}
          >
            <ArrowForwardIcon />
          </Button>
        </Grid>
      )}
    </Fragment>
  );
}
