import React from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
// Import React if not already imported

import { Autocomplete } from "../../../../components";
import { useAlert, useAxios } from "../../../../hooks";

export default function FormEighth({ formikProps, editState, brandDetails }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch(); // Fix typo: Change `disptach` to `dispatch`

  const [templateDetails, setTemplateDetails] = useState([]);
  const { activeBrand, agent } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    axios({
      url:
        "/GetAllTemplates?brand_id=" +
        (formikProps.values.brandID
          ? formikProps.values.brandID
          : activeBrand.ID),
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplateDetails(response.data.templates);
      } else {
        dispatch(
          alert({
            message: response.message,
            type: "error",
          })
        );
      }
    });
  }, [axios, formikProps.values.brandID, activeBrand.ID, dispatch, alert]);

  function handleChangeAudience(value, type, name, primaryKey) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        [name]: {
          value: value[primaryKey],
        },
      }));
    } else {
      value = {
        value: "",
        label: "",
      };
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        [name]: {
          value: value.label,
        },
      }));
    }
  }

  return (
    <Fragment>
      <Grid item xs={6}>
        <Autocomplete
          label={"WhatsApp Agent Details"}
          forcePopupIcon={!editState ? false : true}
          name="whatsapp_agent.value"
          placeholder="WhatsApp Agent Details"
          defaults={{
            primaryKey: "agent_id",
            displayLabel: "mobile_number",
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          options={Boolean(agent.length) ? agent : brandDetails?.data?.agent}
          disabled={!editState}
          onChange={(_, value, type) =>
            handleChangeAudience(value, type, "whatsapp_agent", "agent_id")
          }
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          label="WhatsApp Template Details"
          forcePopupIcon={!editState ? false : true}
          name="whatsapp_template.value"
          placeholder="WhatsApp Template Details"
          defaults={{
            primaryKey: "id",
            displayLabel: "name",
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          options={templateDetails}
          disabled={!editState}
          onChange={(_, value, type) =>
            handleChangeAudience(value, type, "whatsapp_template", "id")
          }
          formikProps={formikProps}
        />
      </Grid>
    </Fragment>
  );
}
