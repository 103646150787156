import queryString from "query-string";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { useAxios, useComponent } from "../../hooks";
import {
  companyObject,
  objectFromResponseData,
} from "./company details components/companyDetailsObject";
import Form from "./company details components/Form";
import { Loader } from "../../components";

export default function CompanyDetails() {
  const axios = useAxios();
  const { loader } = useComponent();
  let ID = "";

  const brandID =
    useSelector((state) => state.memberDetails?.activeBrand.ID) ||
    queryString.parse(window.location.search).brandID ||
    sessionStorage.getItem("brandID");

  const agencyID =
    useSelector((state) => state.memberDetails?.agencyID) ||
    queryString.parse(window.location.search).agencyID ||
    sessionStorage.getItem("agencyID");

  const role = sessionStorage.getItem("role");

  const details = queryString.parse(window.location.search).withCompany;

  const [formData, setFormData] = useState(companyObject);
  const [editState, setEditState] = useState(brandID ? false : true);

  if (role === "NORMAL_USER" && brandID) {
    ID = brandID;
  }

  if (role === "AGENCY" && agencyID) {
    ID = agencyID;
  }

  useEffect(() => {
    loader.start(1);

    if (details) {
      axios({
        url: "/getCompany/" + ID + "?type=" + role,
      }).then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data));
          if (response.data.is_brand_verified === 1) {
            setEditState(false);
          }
          loader.stop();
        } else {
          loader.stop();
        }
      });
    } else {
      loader.stop();
    }
  }, [axios, details, brandID, loader, ID, role]);

  return (
    <Loader>
      <Form
        agencyID={agencyID}
        brandID={brandID}
        ID={ID}
        formData={formData}
        setFormData={setFormData}
        setEditState={setEditState}
        editState={editState}
      />
    </Loader>
  );
}
