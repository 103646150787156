import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import {
  AutocompleteMultiSelect,
  ComponentHeader,
} from "../../../../components";

export default function FormSeventh({
  component = "",
  formikProps,
  editState,
  preferredChannels,
}) {
  const { agent, role } = useSelector((state) => state.memberDetails);

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Preferred Channel"}
          subtitle={
            component === ""
              ? "Select channel where you want to run the campaign"
              : "Selected Channel"
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteMultiSelect
          required={true}
          forcePopupIcon={!editState ? false : true}
          label={"Select Preferred Channel"}
          name="pref_campaign_channel.value"
          formikProps={formikProps}
          disabled={!editState}
          placeholder="Select Preferred Channel"
          defaults={{
            primaryKey: "id",
            displayLabel: "channel_name",
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          options={
            agent.length || role === "SUPER_ADMIN"
              ? preferredChannels
              : preferredChannels.filter((item) => item.id !== 1)
          }
          getOptionDisabled={(option) => !option.is_active}
          onChange={(_, arr, reason, option) =>
            handleChangeChannel(
              arr,
              option,
              "pref_campaign_channel.value",
              "id"
            )
          }
        />
      </Grid>
    </Fragment>
  );

  function handleChangeChannel(arr, { option }, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
      };
    }
  }
}
