import { Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FieldName from "./FieldName";
import {
  ButtonFooter,
  FloatingEditActionButtons,
  PageHeader,
  Paper,
} from "../../../components";
import { useAxios, useComponent } from "../../../hooks";
import { PrimaryLoadingButton } from "../../css components/Button";
import EditButtons from "../../../components/EditButtons";

export default function FormDisplay({
  brands,
  fields,
  editState,
  formikProps,
  setEditState,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const { alert } = useComponent();
  const [loading, setLoading] = useState(false);

  function handleConnection() {
    setLoading(true);
    axios({
      url: "/user/testConnectionDetails",
      method: "POST",
      data: {
        connection_url: formikProps.values.connectionUrl,
        port: formikProps.values.port,
        token: formikProps.values.token,
      },
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        formikProps.setValues((prev) => ({
          ...prev,
          connection:
            res.data[0].connection_establishment === "success" ? true : false,
          fieldNames: res.data[1].column_names,
        }));
        dispatch(
          alert.success(res.data[0]?.connection_establishment || "Success")
        );
      } else {
        dispatch(
          alert.error(res.data[0]?.connection_establishment || "Failed")
        );
      }

      setLoading(false);
    });
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Onboarded Brand Details"}
          pageHeader={formikProps.values.brandDetails?.brand_name}
          buttons={[
            <EditButtons
              ID={formikProps.values.ID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
            />,

            <PrimaryLoadingButton
              loading={loading}
              variant="contained"
              onClick={handleConnection}
              disabled={
                !formikProps.values.connectionUrl && !formikProps.values.port
              }
            >
              Test Connection
            </PrimaryLoadingButton>,
          ]}
        />
      </Grid>

      <Grid item xs={6}>
        <Paper gridSpacing={6}>
          <FormOne
            formikProps={formikProps}
            brands={brands}
            editState={editState}
          />
        </Paper>

        {formikProps.values.connection && (
          <Paper gridSpacing={6}>
            <FieldName formikProps={formikProps} />
          </Paper>
        )}
      </Grid>

      <Grid item xs={6}>
        {fields.length > 0 && (
          <Paper gridSpacing={6}>
            <FormTwo
              formikProps={formikProps}
              fields={fields}
              editState={editState}
            />
          </Paper>
        )}
      </Grid>

      <FloatingEditActionButtons
        ID={formikProps.values.connectionID}
        editState={editState}
        setEditState={setEditState}
        formikProps={formikProps}
      />

      {editState && (
        <Grid item xs={12}>
          <ButtonFooter
            ID={formikProps.values.connectionID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </Grid>
      )}
    </Grid>
  );
}
