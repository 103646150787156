import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  PrimaryButton,
  Reject,
  Accept,
  Pending,
  AcceptUpload,
} from "../../css components/Button";
import pages from "../../../constants/pages";

export default function CampaignButtons({
  liveUsers,
  formikProps,
  component,
  brandIdentity,
  setTakeUpOffer,
  setDialogUploadCSV,
  setBroadcastReportDialog,
}) {
  const navigate = useNavigate();
  console.log(formikProps);
  return (
    <Fragment>
      {component === "" &&
        formikProps.values?.campaignID &&
        formikProps.values?.status === "ACTIVE" &&
        formikProps.values.permissionSettings.display_stats && (
          <PrimaryButton
            onClick={() =>
              navigate(
                pages.analytics.route +
                  formikProps.values.campaignID +
                  "?campaignName=" +
                  formikProps.values?.campaign_name?.value
              )
            }
            variant="contained"
          >
            Analytics
          </PrimaryButton>
        )}

      {component === "superadmin" && (
        <Accept
          size="large"
          variant="contained"
          style={{ marginRight: "15px" }}
        >
          Approve Campaign
        </Accept>
      )}

      {brandIdentity &&
        component !== "accepted" &&
        formikProps.values.status !== "ACTIVE" && (
          <Fragment>
            {formikProps.values.campaignStatus !== "REJECTED" &&
              formikProps.values.status !== "ACTIVE" && (
                <Reject
                  size="large"
                  variant="contained"
                  style={{ marginRight: "15px" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "REJECTED",
                      state: true,
                    }))
                  }
                >
                  Reject
                </Reject>
              )}

            {formikProps.values.campaignStatus === "REQUESTED" && (
              <Pending
                size="large"
                variant="contained"
                style={{ marginRight: "15px" }}
                onClick={() =>
                  setTakeUpOffer((prevVal) => ({
                    ...prevVal,
                    action: "PENDING",
                    state: true,
                  }))
                }
              >
                Pending
              </Pending>
            )}

            {formikProps.values.campaignStatus !== "ACCEPTED" &&
              formikProps.values.campaignStatus !== "MISSED" && (
                <Accept
                  size="large"
                  variant="contained"
                  disabled={
                    parseInt(liveUsers?.liveUser) <= 0 || !liveUsers.liveUser
                  }
                  style={{ marginRight: "15px" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "ACCEPTED",
                      state: true,
                    }))
                  }
                >
                  Accept
                </Accept>
              )}
          </Fragment>
        )}

      {component === "request" &&
        formikProps.values.csvUpload &&
        (formikProps.values.campaignStatus === "REQUESTED" ||
          formikProps.values.campaignStatus === "REJECTED") && (
          <AcceptUpload
            variant="contained"
            onClick={() => setDialogUploadCSV(true)}
          >
            UPLOAD ACCEPT
          </AcceptUpload>
        )}

      {formikProps.values.isBroadcast && (
        <PrimaryButton
          variant="outlined"
          size="large"
          sx={{
            marginLeft: "5px",
            display: Boolean(component === "request") && "none",
            whiteSpace: "nowrap",
          }}
          onClick={() => setBroadcastReportDialog(true)}
        >
          Broadcast Report
        </PrimaryButton>
      )}
    </Fragment>
  );
}
