import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { sentenceCase } from "change-case-all";
import { Grid, Button, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Fragment, useCallback, useEffect, useState } from "react";

import EmptyList from "../../misc/EmptyList";
import pages from "../../../constants/pages";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import Search from "../campaign list components/campaign consumer list components/Search";
import FloatingAddActionButtons from "../../../components/FloatingAddActionButton";
import SortBy from "../campaign list components/campaign consumer list components/SortBy";
import { getAllCampaigns } from "../create campaign components/campaign form components/campaignObject";

export default function Campaign() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  const [categorize] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [sortBy, setSortBy] = useState(categorize === "" ? "" : "ACTIVE");

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start();
    if (!searchKey) {
      let url =
        "/user/brand/getCampaignsByBrand/?brandId=" +
        activeBrand.ID +
        `&page=${page}&limit=10` +
        (sortBy ? `&campaignStatus=${sortBy}` : "");

      axios({
        url: url,
      }).then((response) => {
        if (response.status) {
          setCampaigns(getAllCampaigns(response, activeBrand));

          setLastPage({
            totalCount: response.data.totalCounts,
            lastPage: response.data.campaignData.length < 10,
          });

          loader.stop();
        } else {
          loader.stop();
        }
      });
    }
  }, [
    axios,
    categorize,
    activeBrand.ID,
    loader,
    sortBy,
    activeBrand,
    searchKey,
    page,
  ]);

  const getSearchedCampaign = useCallback(
    debounce(async (search, page) => {
      let url =
        "/user/brand/getCampaignsByBrand/?brandId=" +
        activeBrand.ID +
        `&page=${page}&limit=10` +
        (search ? `&search=${encodeURIComponent(search)}` : "") +
        (sortBy ? `&campaignStatus=${sortBy}` : "") +
        (categorize !== "All"
          ? `&campaignStatus=${categorize.toUpperCase()}`
          : "");

      axios({
        url: url,
      }).then((response) => {
        if (response.status) {
          setCampaigns(getAllCampaigns(response, activeBrand));

          setLastPage({
            totalCount: response.data.totalCounts,
            lastPage: response.data.campaignData.length < 10,
          });

          loader.stop();
        } else {
          loader.stop();
        }
      });
    }, 500), // Adjust debounce delay as needed
    []
  );

  useEffect(() => {
    if (searchKey.trim() !== "") {
      getSearchedCampaign(searchKey, page);
    }
  }, [searchKey, page, getSearchedCampaign]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            icons={[
              <SortBy
                sortBy={sortBy}
                setPage={setPage}
                setSortBy={setSortBy}
              />,
              <Search
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
            pageHeader={"List of campaigns"}
            pageName={sentenceCase(`${sortBy.replace("_", " ")} Campaigns`)}
          />
        </Grid>
      </Grid>

      <FloatingAddActionButtons
        route={pages.createCampaign.route}
        title={"Add Campaign"}
      />

      <Loader height="75%">
        {campaigns.length > 0 ? (
          <List campaigns={campaigns} component="" />
        ) : (
          <EmptyList
            button={"Create Campaign"}
            route={pages.createCampaign.route}
            title={"No Campaigns found."}
          />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            onClick={() => handlePagination("back")}
            disabled={page < 2}
          >
            <ArrowBack />
          </Button>

          {Array(Math.ceil(lastPage.totalCount / 10))
            .fill(0)
            .map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="h5"
                  alignContent={"center"}
                  sx={{
                    mx: 1,
                    p: "10px",
                    borderRadius: "10px",
                    ...(index + 1 === page
                      ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                      : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                  }}
                  onClick={() => handlePagination("index", index)}
                >
                  {index + 1}
                </Typography>
              );
            })}

          <Button
            variant="outlined"
            onClick={() => handlePagination("next")}
            disabled={page === Math.ceil(lastPage.totalCount / 10)}
          >
            <ArrowForward />
          </Button>
        </Grid>
      )}
    </Fragment>
  );
}
