import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, Typography, InputAdornment, IconButton } from "@mui/material";

import { TextField } from "../../../components";
import { PrimaryLoadingButton } from "../../css components/Button";
import Logo from "../../../assets/auth carousel pics/verismart-background.png";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} textAlign={"center"}>
        <img style={{ height: "50px" }} src={Logo} alt="logo" />
        <Typography className="font">
          Make your campaign management easy and fun!
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="First Name"
          required
          name="givenName"
          formikProps={formikProps}
          placeholder="First Name"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          label="Last Name"
          name="familyName"
          placeholder="Last Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          label="Display Name"
          name="displayName"
          placeholder="Display Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          label="Email Address"
          name="email"
          placeholder="Email Address"
          formikProps={formikProps}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <TextField
          required
          select
          label="Register As"
          name="role_name"
          placeholder="Register As"
          formikProps={formikProps}
        >
          <MenuItem value="NORMAL_USER">Brand</MenuItem>
          <MenuItem value="AGENCY">Agency</MenuItem>
        </TextField>
      </Grid> */}

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          label="Password"
          placeholder="Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword && <VisibilityOff />}
                  {showPassword && <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={confirmPassword ? "text" : "password"}
          name="confirmPassword"
          label="Confirm password"
          placeholder="Confirm password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {!confirmPassword && <VisibilityOff />}
                  {confirmPassword && <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Sign Up
        </PrimaryLoadingButton>
      </Grid>

      <Grid item xs={12} alignSelf="center" textAlign={"center"}>
        <Typography className="font" variant="caption1">
          Already have an account?{" "}
          <Typography
            variant="button"
            className="purple font"
            style={{ cursor: "pointer", fontWeight: 500 }}
            onClick={() => navigate("/sign-in")}
          >
            Sign In!
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
