import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Tooltip } from "@mui/material";

import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import {
  HeadingEighteen,
  TextFourteenBlack,
  colors,
} from "../../css components/Style";
import { CgEye } from "react-icons/cg";

const Icon = styled(IconButton)`
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  height: 35px;
  width: 35px;
  margin: 0 5px;
  padding: 0 5px;
  &:hover {
    border: 1px solid ${colors.purple};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

const Image = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const OuterCard = styled(Paper)`
  display: flex;
  gap: 20px;
  margin: 8px 0;
  padding: 10px;
`;

export default function List({ dpList }) {
  const navigate = useNavigate();

  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  return (
    <Grid container spacing={2}>
      {dpList?.map((connection) => (
        <Grid item xs={12} key={connection.brand_id}>
          <OuterCard elevation={1}>
            <Grid item alignSelf="center" paddingRight={"20px"}>
              <Image
                alt="1"
                src={connection.brandDetails.logo}
                onError={onImageError}
              />
            </Grid>

            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextFourteenBlack>Brand Name </TextFourteenBlack>

                  <HeadingEighteen color={colors.purple}>
                    {connection?.brandDetails?.brand_name}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={3}>
                  <TextFourteenBlack>Connection Url</TextFourteenBlack>
                  <HeadingEighteen>
                    {connection?.connectionDetails?.connection_url || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Port</TextFourteenBlack>
                  <HeadingEighteen>
                    {connection?.connectionDetails?.port || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid item xs={2}>
                  <TextFourteenBlack>Status</TextFourteenBlack>
                  <HeadingEighteen>
                    {connection?.connectionDetails?.status || "N.A"}
                  </HeadingEighteen>
                </Grid>

                <Grid
                  item
                  xs={2}
                  alignSelf={"end"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Tooltip title="View Brand">
                    <Icon
                      onClick={() =>
                        navigate(
                          pages.dpOnBoardingDetails.route +
                            connection.connectionDetails.connection_id +
                            "?brandID=" +
                            connection?.connectionDetails?.brand_id
                        )
                      }
                    >
                      <CgEye style={{ height: "20px", width: "20px" }} />
                    </Icon>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </OuterCard>
        </Grid>
      ))}
    </Grid>
  );
}
