import { Button, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import List from "./user list components/List";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import EmptyList from "../misc/EmptyList";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export default function UsersList() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [usersList, setUserList] = useState([]);
  const [userStatus, setUserStatus] = useState("");
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  useEffect(() => {
    loader.start();
    axios({
      url: `/users/unverified/accounts?page=${page}&limit=10`,
    }).then((response) => {
      if (response.status) {
        setUserList(response.data.usersInfo);

        setLastPage({
          totalCount: response.data.totalCount,
          lastPage: response.data.usersInfo.length < 10,
        });
        loader.apiComplete();
      }
    });
  }, [loader, axios, page]);

  useEffect(() => {
    if (userStatus) {
      axios({
        url: `/users/unverified/accounts?page=${page}&limit=10`,
      }).then((response) => {
        if (response.status) {
          setUserList(response.data.usersInfo);

          setLastPage({
            totalCount: response.data.totalCount,
            lastPage: response.data.usersInfo.length < 10,
          });
        }
      });
    }
  }, [userStatus, axios, page]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"New Account Request"}
            pageHeader="Account request list"
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {usersList.length > 0 ? (
          <List usersList={usersList} setUserStatus={setUserStatus} />
        ) : (
          <EmptyList title={"No new accounts"} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            onClick={() => handlePagination("back")}
            disabled={page < 2}
          >
            <ArrowBack />
          </Button>

          {Array(Math.ceil(lastPage.totalCount / 10))
            .fill(0)
            .map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="h5"
                  alignContent={"center"}
                  sx={{
                    mx: 1,
                    p: "10px",
                    borderRadius: "10px",
                    ...(index + 1 === page
                      ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                      : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                  }}
                  onClick={() => handlePagination("index", index)}
                >
                  {index + 1}
                </Typography>
              );
            })}

          <Button
            variant="outlined"
            onClick={() => handlePagination("next")}
            disabled={page === Math.ceil(lastPage.totalCount / 10)}
          >
            <ArrowForward />
          </Button>
        </Grid>
      )}
    </Fragment>
  );
}
