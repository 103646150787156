import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import Connections from "./ConnectionList";
import ActionButton from "./ActionButtons";
import { PageHeader, Paper } from "../../../components";
import FormOne from "../create campaign components/campaign form components/CreateCampaignFirstForm";
import FormTwo from "../create campaign components/campaign form components/CreateCampaignSecondForm";
import FormThird from "../create campaign components/campaign form components/CreateCampaignThirdForm";
import FormFifth from "../create campaign components/campaign form components/CreateCampaignFifthForm";
import FormSeventh from "../create campaign components/campaign form components/PreferredCampaignChannel";
import FormEighth from "../create campaign components/campaign form components/SelectCampaignChannel";

export default function CampaignForm({
  categories,
  audiences,
  formikProps,
  component = "",
  brandDetails,
  audienceData,
  connections,
  setEditState,
  editState,
  preferredChannels,
  setBrandDetails,
  setConnections,
  setRetry,
  retry,
  audienceSizing,
  setAudienceSizing,
}) {
  const [brandIdentity, setBrandIdentity] = useState(false);
  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (formikProps.values.brandID) {
      if (parseInt(formikProps.values.brandID) !== parseInt(activeBrand.ID)) {
        setBrandIdentity(true);
      }
    }
  }, [formikProps.values.brandID, activeBrand.ID]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Campaign Details"}
          pageHeader={formikProps.values.campaign_name.value}
          buttons={[
            <ActionButton
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              ID={formikProps.values.campaignID}
              audienceSizing={audienceSizing}
              setAudienceSizing={setAudienceSizing}
            />,
          ]}
        />
      </Grid>

      <Connections
        retry={retry}
        setRetry={setRetry}
        connections={connections}
        setConnections={setConnections}
      />

      <Grid item xs={12}>
        <Paper gridSpacing={6}>
          <FormOne
            categories={categories}
            brandDetails={brandDetails}
            formikProps={formikProps}
            editState={editState}
            component={component}
            setBrandDetails={setBrandDetails}
            brandIdentity={brandIdentity}
          />
        </Paper>

        <Paper gridSpacing={6}>
          <FormTwo
            formikProps={formikProps}
            audiences={audiences}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
            audienceData={audienceData}
          />
        </Paper>

        <Paper gridSpacing={6}>
          <FormThird
            brandIdentity={brandIdentity}
            formikProps={formikProps}
            editState={editState}
            component={component}
          />
        </Paper>

        <Paper gridSpacing={6}>
          <FormSeventh
            component="superadmin"
            editState={editState}
            formikProps={formikProps}
            preferredChannels={preferredChannels}
          />

          {formikProps.values?.pref_campaign_channel?.value?.length !== 0 &&
            formikProps.values.pref_campaign_channel.value.includes(1) && (
              <FormEighth
                formikProps={formikProps}
                editState={editState}
                brandDetails={brandDetails}
              />
            )}
        </Paper>

        <Paper gridSpacing={6}>
          <FormFifth
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
