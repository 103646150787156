import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./companyDetailsObject";
import { Fragment } from "react";
import Agreement from "../agreement components/Agreement";

export default function Form({
  agencyID,
  brandID,
  formData,
  setFormData,
  editState,
  setEditState,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/user/companyProfile",
          method: "POST",
          disableRedirect: true,
          data: objectFromFormData(formData, brandID, agencyID),
        })
          .then((response) => {
            if (response.status) {
              if (formData.ID) {
                setEditState(false);
              }

              setFormData((prev) => ({
                ...prev,
                getAgreement: response.data?.get_agreement,
                signAgreement: response.data?.sign_agreement,
              }));

              if (!formData.ID && brandID) {
                navigate(
                  pages.companyDetailsEmpty.route +
                    "?brandID=" +
                    brandID +
                    "&withCompany=" +
                    true
                );
              }

              if (!formData.ID && agencyID) {
                navigate(
                  pages.companyDetailsEmpty.route +
                    "?agencyID=" +
                    agencyID +
                    "&withCompany=" +
                    true
                );
              }

              dispatch(
                alert({
                  type: "success",
                  message: response.message.displayMessage,
                })
              );
            } else {
              dispatch(
                alert({
                  type: "error",
                  message: response.message,
                })
              );
            }
            setSubmitting(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            agencyID={agencyID}
            brandID={brandID}
            formikProps={formikProps}
            setEditState={setEditState}
            editState={editState}
          />

          {formikProps.values.ID &&
            formikProps.values.mandatoryDetailsSubmitted && (
              <Agreement brandID={brandID} formikProps={formikProps} />
            )}
        </Fragment>
      )}
    </Formik>
  );
}
