import { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import ActionButton from "./ActionButtons";
import AdminBrandStatus from "./BrandStatus";
import { PageHeader, Paper } from "../../../components";
import PrefrredChannels from "./PreffredChannels";
import { useAxios } from "../../../hooks";

export default function FormDisplay({
  formData,
  setFormData,
  location,
  languages,
  categories,
}) {
  const axios = useAxios();
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    axios({
      url: "/get/campaign/preferredChannels",
      disableRedirect: true,
      disableError: true,
    }).then((response) => {
      if (response.status) {
        setChannels(response.data);
      }
    });
  }, [axios]);

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"Brand Details"}
          twoText={true}
          firstText={formData.brandName}
          fontSize="25px"
          secondText={""}
          buttons={[
            <ActionButton
              formData={formData}
              ID={formData.brandID}
              setFormData={setFormData}
            />,
          ]}
        />
      </Grid>

      <AdminBrandStatus formData={formData} />

      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Paper gridSpacing={3}>
            <FormOne formData={formData} />

            <FormTwo
              formData={formData}
              location={location}
              languages={languages}
              categories={categories}
            />
          </Paper>

          {formData.brandType === 2 && (
            <Grid item xs={12}>
              <Paper gridSpacing={6}>
                <PrefrredChannels channels={channels} formData={formData} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
