import { useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import EmptyList from "../../misc/EmptyList";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import RequestedList from "../campaign list components/CampaignRequestList";
import SortBy from "../campaign list components/campaign dp list components/SortBy";

import Categorize from "../campaign list components/campaign dp list components/Categorize";
import { toInternationalFormat } from "../../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Search from "./../campaign list components/campaign dp list components/Search";
import { colors } from "../../css components/Style";

export default function CampaignDP() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });
  const [searchKey, setSearchKey] = useState("");
  const [sortBy, setSortBy] = useState("ALL");

  const [campaigns, setCampaigns] = useState([]);
  const [categorize, setCategorize] = useState("REQUESTED");

  const { activeBrand, userID } = useSelector((state) => state.memberDetails);

  const options = [
    { value: "ACTIVE", label: "Active Campaigns", color: colors.green },
    { value: "REQUESTED", label: "Campaign Requests", color: colors.yellow },
  ];

  let sortList = [
    { value: "ALL", label: "All" },
    { value: "INPROGRESS", label: "In Progress", color: colors.green },
    { value: "EXPIRING", label: "Expiring Soon", color: colors.yellow },
    { value: "EXPIRED", label: "Expired", color: colors.error },
  ];

  if (categorize === "REQUESTED") {
    sortList = [
      { value: "", label: "All" },
      { value: "REQUESTED", label: "Requested", color: colors.yellow },
      { value: "ACCEPTED", label: "Accepted", color: colors.green },
      { value: "PENDING", label: "Pending", color: colors.warning },
      { value: "REJECTED", label: "Rejected", color: colors.error },
    ];
  }

  useEffect(() => {
    loader.start(1);

    if (categorize === "ACTIVE") {
      axios({
        url:
          "/user/acceptedCampaigns" +
          `?page=${page}&limit=10` +
          (searchKey ? `&search=${encodeURIComponent(searchKey)}` : "") +
          (sortBy ? `&requestStatus=${sortBy}` : ""),
        method: "POST",
        data: {
          provider_brandId: activeBrand.ID,
          provider_userId: userID,
        },
      }).then((response) => {
        if (response.status) {
          let arr = [];

          for (let key of response.data.sortedRequests) {
            let data = {};
            for (let fields of key.fields) {
              if (
                Object.keys(fields)[0] === "budget_min" ||
                Object.keys(fields)[0] === "budget_max" ||
                Object.keys(fields)[0] === "monthly_active_user" ||
                Object.keys(fields)[0] === "volume"
              ) {
                data[Object.keys(fields)[0]] = toInternationalFormat(
                  Object.values(fields)[0]
                );
              } else {
                data[Object.keys(fields)[0]] = Object.values(fields)[0];
              }
            }
            arr.push({
              ...data,
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.status,
              audience_id: key.audience_id,
              brand_id: key.brand_id,
              campaign_id: key.campaign_id,
              brand: key.brand_info,
              user_Count: key.user_Count,
            });
          }

          setCampaigns(arr);

          setLastPage({
            totalCount: response.data.totalCounts,
            lastPage: response.data.sortedRequests.length < 10,
          });

          loader.apiComplete();
        } else {
          loader.apiComplete();
        }
      });
    }

    if (categorize === "REQUESTED") {
      axios({
        url:
          "/user/showCampaignRequestsDP?brandId=" +
          activeBrand.ID +
          `&page=${page}&limit=10` +
          (searchKey ? `&search=${searchKey}` : "") +
          (sortBy ? `&requestStatus=${sortBy}` : ""),
      }).then((response) => {
        if (response.status) {
          let campaign = [];

          for (let key of response.data.sortedRequests) {
            let data = {};
            for (let fields of key.campaign_fields) {
              if (
                Object.keys(fields)[0] === "budget_min" ||
                Object.keys(fields)[0] === "budget_max" ||
                Object.keys(fields)[0] === "monthly_active_user" ||
                Object.keys(fields)[0] === "volume"
              ) {
                data[Object.keys(fields)[0]] = toInternationalFormat(
                  Object.values(fields)[0]
                );
              } else {
                data[Object.keys(fields)[0]] = Object.values(fields)[0];
              }
            }

            campaign.push({
              ...data,
              brand: key.brand_info,
              previewImage: !!key.preview_image
                ? key.preview_image
                : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
              status: key.requestStatus,
              request_id: key.request_id,
              sender_brandId: key.sender_brandId,
              sender_userId: key.sender_userId,
              sender_campaignId: key.sender_campaignId,
              receiver_brandId: key.receiver_brandId,
              receiver_userId: key.receiver_userId,
              isAccepted: key.isAccepted,
              user_Count: key.user_Count,
            });
          }

          setCampaigns(campaign);

          setLastPage({
            totalCount: response.data.totalCounts,
            lastPage: response.data.sortedRequests.length < 10,
          });

          loader.apiComplete();
        } else {
          loader.apiComplete();
        }
      });
    }
  }, [
    axios,
    sortBy,
    categorize,
    userID,
    activeBrand.ID,
    loader,
    page,
    searchKey,
  ]);

  function handlePagination(step, index) {
    setPage((prev) =>
      step === "index" ? index + 1 : prev + (step === "next" ? 1 : -1)
    );
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            icons={[
              <Categorize
                options={options}
                setPage={setPage}
                categorize={categorize}
                setCategorize={setCategorize}
                setSortBy={setSortBy}
              />,
              categorize === "REQUESTED" && (
                <SortBy
                  sortBy={sortBy}
                  setPage={setPage}
                  sortList={sortList}
                  setSortBy={setSortBy}
                  categorize={categorize}
                />
              ),
              <Search
                searchKey={searchKey}
                setPage={setPage}
                setSearchKey={setSearchKey}
              />,
            ]}
            pageHeader={"List of campaigns"}
            pageName={
              categorize === "REQUESTED"
                ? "Campaign Offers"
                : "Active Campaigns"
            }
          />
        </Grid>
      </Grid>

      <Loader height="75%">
        {campaigns.length > 0 ? (
          categorize === "REQUESTED" ? (
            <RequestedList campaigns={campaigns} component="request" />
          ) : (
            <List campaigns={campaigns} component="accepted" />
          )
        ) : (
          <EmptyList title={"No Request found."} />
        )}
      </Loader>

      {!!lastPage.totalCount && (
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Button
            variant="outlined"
            onClick={() => handlePagination("back")}
            disabled={page < 2}
          >
            <ArrowBackIcon />
          </Button>

          {Array(Math.ceil(lastPage.totalCount / 10))
            .fill(0)
            .map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="h5"
                  alignContent={"center"}
                  sx={{
                    mx: 1,
                    p: "10px",
                    borderRadius: "10px",
                    ...(index + 1 === page
                      ? { boxShadow: "rgb(107 79 104) 0px 2px 4px" }
                      : { "&:hover": { backgroundColor: "#dbdbdb" } }),
                  }}
                  onClick={() => handlePagination("index", index)}
                >
                  {index + 1}
                </Typography>
              );
            })}

          <Button
            variant="outlined"
            onClick={() => handlePagination("next")}
            disabled={page === Math.ceil(lastPage.totalCount / 10)}
          >
            <ArrowForwardIcon />
          </Button>
        </Grid>
      )}
    </Fragment>
  );

  function SearchBar() {
    return (
      <Search
        searchKey={searchKey}
        setPage={setPage}
        setSearchKey={setSearchKey}
      />
    );
  }
}
