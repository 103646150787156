import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import EmptyList from "../misc/EmptyList";
import Table from "./template list component/Table";
import { Loader, PageHeader } from "../../components";
import { startLoader, apiComplete } from "../../redux/slices/loaderSlice";
import FloatingAddActionButtons from "../../components/FloatingAddActionButton";

export default function TemplateList() {
  const axios = useAxios({
    baseURL: "cpass_api",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { agent } = useSelector((state) => state.memberDetails);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [templates, setTemplates] = useState([]);
  const [templateDelete, setTemplateDelete] = useState("");
  const [lastPage, setLastPage] = useState({ totalCount: 0, lastPage: false });

  useEffect(() => {
    dispatch(startLoader());

    if (agent?.length > 0) {
      axios({
        url: "/template/" + agent[0].agent_id,
      }).then((response) => {
        if (response.status) {
          setTemplates(response.data);
          dispatch(apiComplete());
        }
      });
    } else {
      dispatch(apiComplete());
    }
  }, [axios, templateDelete, agent, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            pageName={"My Templates"}
            pageHeader="List of Templates"
            icons={
              [
                // <SortBy />,
                // <Search
                //   searchKey={searchKey}
                //   setPage={setPage}
                //   setSearchKey={setSearchKey}
                // />,
              ]
            }
          />
        </Grid>
      </Grid>

      <FloatingAddActionButtons
        route={pages.createTemplate.route}
        title={"Add Template"}
      />

      <Loader height="75%">
        {agent?.length > 0 ? (
          <Table templates={templates} setTemplateDelete={setTemplateDelete} />
        ) : (
          <EmptyList
            button={"Contact Admin"}
            href="mailto:adtech@verismart.ai"
            title={"Contact admin to link numbers"}
          />
        )}
      </Loader>
    </Fragment>
  );
}
