import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import pages from "../../../constants/pages";
import { PrimaryButton } from "../../css components/Button";

export default function WelcomeCardAgency({ steps }) {
  const navigate = useNavigate();
  const brandID = sessionStorage.getItem("agencyID");

  return (
    <div className="wrapper-div">
      <div className="brand-outer" style={{ padding: "15px" }}>
        <div style={{ marginTop: "15px" }}>
          <Typography className="card-heading">
            {!steps.step2
              ? "Let's start by adding the agency details."
              : "Your agency details has been added, Do you want to edit it?"}
          </Typography>
        </div>

        <div className="create-brand-button">
          <PrimaryButton variant="contained" onClick={handleBrandNavigation}>
            {!steps.step2 ? "Add Agency Details" : "Edit Agency Details"}
          </PrimaryButton>
        </div>
      </div>

      <div className="brand-outer" style={{ padding: "15px" }}>
        <div style={{ height: "30px", marginTop: "15px" }}>
          <Typography className="card-heading">
            {!steps.step3
              ? "Let's add your company details."
              : "Your company details has been added, Do you want to edit it?"}
          </Typography>
        </div>

        <div className="create-company-button">
          <PrimaryButton
            variant="contained"
            disabled={!steps.step2}
            onClick={handleCompanyNavigation}
          >
            {!steps.step3 ? "Add Company Details" : "Edit Company Details"}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );

  function handleBrandNavigation() {
    if (steps.step2) {
      navigate(pages.agencyDetailsEmptyPage.route + "?agencyID=" + brandID);
    } else {
      navigate(pages.createAgencyPage.route);
    }
  }

  function handleCompanyNavigation() {
    if (steps.step3) {
      navigate(
        pages.companyDetailsEmpty.route +
          "?brandID=" +
          brandID +
          "&withCompany=" +
          true
      );
    } else {
      navigate(pages.companyDetailsCreateEmpty.route);
    }
  }
}
