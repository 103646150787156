import { Grid, FormControlLabel, Checkbox } from "@mui/material";

import { Autocomplete, TextField, ComponentHeader } from "../../../components";
import { colors } from "../../css components/Style";
import { Fragment } from "react";

export default function FormOne({ formikProps, brands, editState }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader title={"Brand Details"} />
      </Grid>

      <Grid item xs={10}>
        {!!formikProps.values.connectionID ? (
          <TextField
            label="Brand Name"
            name="brandName"
            formikProps={formikProps}
            disabled={!!formikProps.values.connectionID}
          />
        ) : (
          <Autocomplete
            label="Brand Name"
            name="brandID"
            forcePopupIcon={!editState ? false : true}
            placeholder="Select Brand Name"
            defaults={{
              primaryKey: "brand_id",
              displayLabel: "brand_name",
            }}
            options={brands}
            disabled={!!formikProps.values.connectionID}
            onChange={(_, value, type) => handleChangeBrand(value, type)}
            formikProps={formikProps}
          />
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!editState}
                onChange={(e) => {
                  formikProps.setValues((prev) => ({
                    ...prev,
                    csvUpload: e.target.checked,
                  }));
                }}
                checked={formikProps.values.csvUpload}
                sx={{
                  color: colors.purple,
                  "&.Mui-checked": {
                    color: colors.purple,
                  },
                }}
              />
            }
            label="Onboarding with CSV"
          />
        </Grid>
      </Grid>

      {!formikProps.values.csvUpload && (
        <Fragment>
          <Grid item xs={10}>
            <TextField
              name="connectionUrl"
              placeholder="IP/Domain"
              label="IP/Domain"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              name="port"
              placeholder="Port"
              label="Port"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              multiline
              name="token"
              placeholder="Token"
              label="Token"
              disabled={!editState}
              formikProps={formikProps}
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );

  function handleChangeBrand(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brand_id,
      }));
    } else {
      value = {
        brand_id: "",
        brand_name: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brandID,
      }));
    }
  }
}
