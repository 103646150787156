import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, useEffect, Fragment } from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdsClickIcon from "@mui/icons-material/AdsClick";

import CampaignStatus from "../Status";
import FormEighth from "./SelectCampaignChannel";
import FormOne from "./CreateCampaignFirstForm";
import FormTwo from "./CreateCampaignSecondForm";
import FormThird from "./CreateCampaignThirdForm";
import CampaignLiveUser from "../CampaignLiveUser";
import FormFifth from "./CreateCampaignFifthForm";
import ExploreConnection from "../ExploreConnection";
import FormSeventh from "./PreferredCampaignChannel";
import UploadCSV from "../../uploadCSV dailog/UploadCSV";
import AdminCampaignStatus from "../AdminCampaignStatus";
import CampaignButtons from "./../CreateCampaignButtons";
import EditButtons from "../../../../components/EditButtons";
import { ButtonFooter, PageHeader, Paper } from "../../../../components";
import TakeUpDialog from "../../take up offer components/TakeUpDailog";
import RejectOfferDialog from "../../reject offer components/RejectOfferDialog";
import BrandDetailsDialog from "../../brand details components/BrandDetailsDialog";
import FloatingActionButtons from "../../../../components/FloatingEditActionButton";
import BroadcastReportDialog from "../../broadcast report dialog component/BroadcastReportDialog";
import ShowAudienceSizeDialog from "../ShowAudienceSize";
import Connections from "../../superadmin campaign details component/ConnectionList";

const buttonStyles = (isActive) => ({
  fontSize: "16px",
  fontWeight: "500",
  color: isActive ? "white" : "rgb(143, 28, 130)",
  backgroundColor: isActive ? "rgb(143, 28, 130)" : undefined,
  padding: "8.5px 20px",
  borderRadius: "7px",
  // border: "1px solid rgb(143, 28, 130)",
  "&:hover": {
    backgroundColor: isActive ? "rgb(143, 28, 130)" : "rgb(233 136 223)",
    // color: isActive ? "rgb(143, 28, 130)" : "white",
  },
  marginRight: "15px",
  boxShadow: isActive ? "rgb(107 79 104) 0px 2px 4px" : "",
});

export default function FormDisplay({
  audiences,
  editState,
  liveUsers,
  categories,
  rejectOffer,
  formikProps,
  takeUpOffer,
  audienceData,
  setEditState,
  audienceSize,
  component = "",
  brandDetails,
  setTakeUpOffer,
  setBrandDetails,
  setRejectOffer,
  setAudienceData,
  setAudienceCreated,
  preferredChannels,
  connectionList,
}) {
  const [brandIdentity, setBrandIdentity] = useState(false);
  const [dialogUploadCSV, setDialogUploadCSV] = useState(false);
  const [broadcastReportDialog, setBroadcastReportDialog] = useState(false);
  const [tabValue, setTabValue] = useState("Campaign");

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    if (formikProps.values.brandID) {
      if (parseInt(formikProps.values.brandID) !== parseInt(activeBrand.ID)) {
        setBrandIdentity(true);
      }
    }
  }, [formikProps.values.brandID, activeBrand.ID]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Campaigns"}
          pageHeader={
            component === ""
              ? "Let's create campaign"
              : formikProps.values.campaign_name.value
          }
          buttons={[
            tabValue === "Campaign" &&
              formikProps.values.status !== "ACTIVE" && (
                <EditButtons
                  formikProps={formikProps}
                  editState={editState}
                  setEditState={setEditState}
                />
              ),
            <CampaignButtons
              ID={formikProps.values.campaignID}
              editState={editState}
              liveUsers={liveUsers}
              setEditState={setEditState}
              formikProps={formikProps}
              component={component}
              brandIdentity={brandIdentity}
              setTakeUpOffer={setTakeUpOffer}
              setDialogUploadCSV={setDialogUploadCSV}
              setBroadcastReportDialog={setBroadcastReportDialog}
            />,
          ]}
        />

        <Button
          sx={buttonStyles(tabValue === "Campaign")}
          onClick={() => setTabValue("Campaign")}
        >
          <CampaignIcon sx={{ fontSize: "30px", marginRight: "5px" }} />
          Campaign
        </Button>

        {formikProps.values.campaignID && (
          <Button
            sx={buttonStyles(tabValue !== "Campaign")}
            onClick={() => setTabValue("Creative")}
          >
            <AdsClickIcon sx={{ fontSize: "25px", marginRight: "5px" }} />
            {"   "}Creative
          </Button>
        )}
      </Grid>

      {component !== "" &&
        formikProps.values.campaignStatus !== "REQUESTED" && (
          <Grid item xs={12}>
            <CampaignStatus
              formikProps={formikProps}
              setTakeUpOffer={setTakeUpOffer}
            />
          </Grid>
        )}

      {component === "" && !!formikProps.values.campaignID && (
        <Grid item xs={12}>
          <AdminCampaignStatus
            audienceSize={audienceSize}
            formikProps={formikProps}
          />
        </Grid>
      )}

      {tabValue === "Campaign" &&
        component === "" &&
        formikProps.values.permissionSettings?.connection_list && (
          <Connections connections={connectionList} />
        )}

      {tabValue === "Campaign" && (
        <Grid item xs={12}>
          <Paper gridSpacing={6}>
            <FormOne
              categories={categories}
              brandDetails={brandDetails}
              setBrandDetails={setBrandDetails}
              formikProps={formikProps}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
            />
          </Paper>

          <Paper gridSpacing={6}>
            <FormTwo
              setAudienceData={setAudienceData}
              formikProps={formikProps}
              audiences={audiences}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
              audienceData={audienceData}
              setAudienceCreated={setAudienceCreated}
            />
          </Paper>

          <Paper gridSpacing={6}>
            <FormThird
              brandIdentity={brandIdentity}
              formikProps={formikProps}
              editState={editState}
              component={component}
            />
          </Paper>

          {component !== "request" && (
            <Paper gridSpacing={6}>
              <FormSeventh
                editState={editState}
                formikProps={formikProps}
                preferredChannels={preferredChannels}
              />

              {formikProps.values?.pref_campaign_channel?.value?.length !== 0 &&
                formikProps.values.pref_campaign_channel.value.includes(1) && (
                  <FormEighth formikProps={formikProps} editState={editState} />
                )}
            </Paper>
          )}

          {editState && (
            <Grid item xs={12}>
              <ButtonFooter
                ID={formikProps.values.campaignID}
                editState={editState}
                setEditState={setEditState}
                formikProps={formikProps}
              />
            </Grid>
          )}

          {formikProps.values.campaignID && (
            <Fragment>
              {/* <Paper gridSpacing={6}>
              <FormFifth
                formikProps={formikProps}
                editState={editState}
                component={component}
                brandIdentity={brandIdentity}
              />
            </Paper> */}

              <Grid item xs={7}>
                {component !== "request" &&
                  component !== "accepted" &&
                  formikProps.values.permissionSettings?.explore_connection && (
                    <ExploreConnection formikProps={formikProps} />
                  )}
              </Grid>
            </Fragment>
          )}

          {!!(audienceSize?.audience_params || audienceSize?.audience_size) && (
            <ShowAudienceSizeDialog audienceSize={audienceSize} />
          )}

          {brandIdentity && (
            <CampaignLiveUser
              takeUpOffer={takeUpOffer}
              liveUsers={liveUsers}
              component={component}
            />
          )}
        </Grid>
      )}

      {formikProps.values.campaignID && tabValue !== "Campaign" && (
        <Grid item xs={12}>
          <Paper gridSpacing={6}>
            <FormFifth
              formikProps={formikProps}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
            />
          </Paper>
        </Grid>
      )}

      <RejectOfferDialog reject={rejectOffer} setReject={setRejectOffer} />

      <TakeUpDialog
        takeUp={takeUpOffer}
        formikProps={formikProps}
        setTakeUp={setTakeUpOffer}
      />

      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />

      <UploadCSV
        dialogUploadCSV={dialogUploadCSV}
        setDialogUploadCSV={setDialogUploadCSV}
        formikProps={formikProps}
      />

      <BroadcastReportDialog
        broadcastReportDialog={broadcastReportDialog}
        setBroadcastReportDialog={setBroadcastReportDialog}
      />

      {tabValue === "Campaign" && formikProps.values.status !== "ACTIVE" && (
        <FloatingActionButtons
          ID={formikProps.values.campaignID}
          editState={editState}
          setEditState={setEditState}
          formikProps={formikProps}
        />
      )}
    </Grid>
  );
}
