import { Grid, MenuItem } from "@mui/material";

import { Fragment } from "react";

import { ComponentHeader, Label, Paper, TextField } from "../../../components";

export default function FormTwo({ formikProps, fields, editState }) {
  const handleTextChange = (e, index, field, formikProps) => {
    const { name, value } = e.target;
    formikProps.handleChange(e);

    // Check if the first field is empty
    if (name === `fields.${index}.${field.field_name}.value` && !value) {
      // Set the value of the second field to empty
      formikProps.setFieldValue(`fields.${index}.${field.field_name}.type`, "");
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <ComponentHeader title={"Dynamic Fields"} />
      </Grid>

      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Grid item xs={8}>
            <TextField
              label={field.field_label}
              name={"fields." + [index] + "." + [field.field_name] + ".value"}
              placeholder={"Enter " + field.field_label}
              formikProps={formikProps}
              onChange={(e) => handleTextChange(e, index, field, formikProps)}
              disabled={!editState}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name={"fields." + [index] + "." + [field.field_name] + ".type"}
              label={"Select Field Type"}
              formikProps={formikProps}
              disabled={!editState}
              select
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
            </TextField>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );
}
