import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import {
  Grid,
  MenuItem,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
} from "@mui/material";

import { useAxios, useComponent } from "../../../../hooks";
import { TextField, ComponentHeader, FileUpload } from "../../../../components";
import { CancelLoadingButton } from "../../../css components/Button";
import { colors } from "../../../css components/Style";
import ConfirmDialog from "./Confirm";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

const Creative = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: rgba(189, 189, 189, 1);
`;

const ImageDiv = styled.div`
  width: 200px;
  height: 80px;
  border: 1px solid #d6cbcb;
`;

export default function CreativeForm({
  dialogFormikProps,
  editState,
  handleClose,
  formikProps,
  component,
  adSize,
  confirm,
  setConfirm,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography style={{ color: colors.green, fontSize: "18px" }}>
          {component === "request"
            ? "Creative Details"
            : "Let's add creative to your campaign"}
        </Typography>
      </Grid>

      {(!!dialogFormikProps.values.fileUrl ||
        !!dialogFormikProps.values.creative) && (
        <Grid
          item
          xs={12}
          display={"flex"}
          mb={7}
          justifyContent={"space-evenly"}
        >
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="auto"
                image={
                  dialogFormikProps.values.creative ||
                  dialogFormikProps.values.fileUrl
                }
                alt="green iguana"
              />
            </CardActionArea>
          </Card>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          name="friendlyName"
          label="Friendly Name"
          placeholder="Enter Friendly Name"
          disabled={!editState || component === "superadmin"}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Select Format Type"
          select
          formikProps={dialogFormikProps}
          disabled={
            !editState ||
            dialogFormikProps.values.creativeID ||
            component === "superadmin"
          }
          name="format"
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: dialogFormikProps.values.format === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" selected disabled>
            Select Format Type
          </Menu>
          <Menu value="video">Video</Menu>
          <Menu value="image">Image</Menu>
          <Menu value="ad-tag">Ad-Tag</Menu>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        {dialogFormikProps.values.format !== "ad-tag" && (
          <TextField
            label="Select Source Type"
            select
            formikProps={dialogFormikProps}
            disabled={!editState || component === "superadmin"}
            name="sourceType"
            sx={{
              "& .MuiSvgIcon-root": {
                display: !editState && "none",
              },
            }}
            SelectProps={{
              displayEmpty: true,
            }}
            InputProps={{
              style: {
                color: dialogFormikProps.values.upload === "" && "#bababacf",
              },
            }}
          >
            <Menu value="" selected disabled>
              Select Source Type
            </Menu>
            <Menu value="upload">Upload From Device</Menu>
            <Menu value="link">Paste URL</Menu>
          </TextField>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Select Creative Size"
          select
          disabled={!editState || component === "superadmin"}
          formikProps={dialogFormikProps}
          name="adSize"
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: dialogFormikProps.values.adSize === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" selected disabled>
            Select Creative Size
          </Menu>
          {adSize.map((size) => (
            <Menu value={size.id}>
              {size.name}- {size.height}X{size.width}
            </Menu>
          ))}
        </TextField>
      </Grid>

      {dialogFormikProps.values.format !== "ad-tag" && (
        <Fragment>
          {dialogFormikProps.values.sourceType === "upload" && (
            <Fragment>
              <Grid item xs={12}>
                <FileUpload
                  // label="Upload Creative File"
                  required
                  disabled={!editState || component === "superadmin"}
                  name="creative"
                  formikProps={dialogFormikProps}
                  placeholder="Upload Creative File"
                  allowedExtension={"jpg,.jpeg,.png,.gif"}
                  folderPath={"public/creatives/" + new Date().getTime() + "_"}
                />
              </Grid>
            </Fragment>
          )}

          <Fragment>
            {dialogFormikProps.values.sourceType === "link" && (
              <Fragment>
                <Grid item xs={12}>
                  <TextField
                    label="Enter Link"
                    name="fileUrl"
                    placeholder="Enter Link"
                    disabled={!editState || component === "superadmin"}
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              </Fragment>
            )}
          </Fragment>
        </Fragment>
      )}

      {dialogFormikProps.values.format === "ad-tag" && (
        <Fragment>
          <Grid item xs={12}>
            <TextField
              label="Enter ad tag"
              multiline
              required
              name="adTag"
              disabled={!editState || component === "superadmin"}
              formikProps={dialogFormikProps}
              placeholder="Enter ad tag"
            />
          </Grid>
        </Fragment>
      )}

      <ConfirmDialog
        confirm={confirm}
        setConfirm={setConfirm}
        formikProps={formikProps}
        dialogFormikProps={dialogFormikProps}
        handleClose={handleClose}
      />
    </Grid>
  );

  function onDelete() {
    setLoading(true);
    axios({
      url:
        "/user/brand/campaign/creatives?creativeId=" +
        dialogFormikProps.values.creativeID,
      method: "DELETE",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setLoading(false);

        dispatch(alert.success("Creative deleted successfully."));
        formikProps.setValues((prev) => ({
          ...prev,
          creatives: setCreatives(prev.creatives),
        }));

        function setCreatives(creatives) {
          let arr = creatives.filter(
            (creative) =>
              creative.creativeID !== dialogFormikProps.values.creativeID
          );
          return arr;
        }
        handleClose();
      }
    });
  }
}
