import { Fragment } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { AiFillHome } from "react-icons/ai";
import {
  Divider,
  MenuItem as MuiMenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";

import pages from "../../../../constants/pages";
import { signOutMember } from "../../../../redux/slices/memberSlice";
import { colors } from "../../../../pages/css components/Style";

const MenuItem = styled(MuiMenuItem)`
  min-width: 200px;
`;

export default function DropDownMenu({ role, setMenuAnchor }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Fragment>
      {role !== "SUPER_ADMIN" && (
        <>
          <MenuItem
            onClick={() => {
              setMenuAnchor(null);
              navigate(pages.brandDetails.route);
            }}
          >
            <ListItemIcon>
              <AiFillHome color={colors.purple} fontSize="medium" />
            </ListItemIcon>
            <Typography variant="h6" color={colors.purple}>
              Home
            </Typography>
          </MenuItem>
          <Divider style={{ marginBlock: 7 }} />
        </>
      )}

      {role !== "SUPER_ADMIN" && (
        <>
          <MenuItem
            onClick={() => {
              setMenuAnchor(null);
              navigate(pages.profilePage.route);
            }}
          >
            <ListItemIcon>
              <FaUserCircle color={colors.purple} fontSize="medium" />
            </ListItemIcon>

            <Typography variant="h6" color={colors.purple}>
              Profile
            </Typography>
          </MenuItem>
          <Divider style={{ marginBlock: 7 }} />
        </>
      )}
      <MenuItem onClick={() => dispatch(signOutMember())}>
        <ListItemIcon>
          <IoMdLogOut color={colors.purple} fontSize="medium" />
        </ListItemIcon>

        <Typography variant="h6" color={colors.purple}>
          Sign Out
        </Typography>
      </MenuItem>
    </Fragment>
  );
}
