import * as Yup from "yup";
import { useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { objectFromFormData } from "./audienceObjects";

export default function Form({
  fields,
  location,
  languages,
  formData,
  component,
  editState,
  sliderValue,
  categories,
  campaignID,
  handleClose,
  setEditState,
  setSliderValue,
  dialogDetails,
  setAudienceCreated,
  campaignFormikProps,
  percentageSliderValue,
  setPercentageSliderValue,
}) {
  const axios = useAxios();

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);

  const [percentageSliderValueDialog, setPercentageSliderValueDialog] =
    useState(percentageSliderValue === undefined ? -1 : percentageSliderValue);

  const memberDetails = useSelector((state) => state.memberDetails);

  let baseSchema = Yup.object().shape({
    additionalFields: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Additional Fields Key is required"),
        value: Yup.string().required("Additional Fields Value is required"),
      })
    ),
    audience_group: Yup.object().shape({
      value: Yup.string()
        .min(3, "Greater than 3 letters")
        .required("Audience Group is required"),
    }),
    mandatoryTrueCount: Yup.string().matches(
      /^[1-9]+$/,
      "One Field Need To Be Mandatory True"
    ),
  });

  for (let field of fields) {
    if (field.control_id === 1) {
      baseSchema = baseSchema.concat(
        Yup.object({
          [field.name]: Yup.object().shape({
            value: Yup.object().shape({
              min: Yup.number()
                .nullable()
                .moreThan(
                  field.min_default_val - 1,
                  field.label +
                    " should be greater than " +
                    field.min_default_val
                )
                .lessThan(
                  field.max_default_val + 1,
                  field.label + " should be less than " + field.max_default_val
                ),
              max: Yup.number().when("min", {
                is: (val) => val,
                then: Yup.number()
                  .nullable()
                  .lessThan(
                    field.max_default_val + 1,
                    field.label +
                      " should be less than " +
                      field.max_default_val
                  )
                  .moreThan(Yup.ref("min"), "Max  should be greater")
                  .required(field.label + " is required"),
              }),
            }),
          }),
        })
      );
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={baseSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        let url = "/brand/audience";
        let method = "POST";

        if (!!formData.ID) {
          url = "/brand/audience/" + formData.ID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          disableRedirect: true,
          data: objectFromFormData(formData, memberDetails),
        })
          .then((res) => {
            if (res.status) {
              if (component !== "dialog") {
                if (!!formData.ID) {
                  setEditState(false);
                  dispatch(
                    alert({
                      message: "Audience updated successfully!",
                      type: "success",
                    })
                  );
                } else {
                  dispatch(
                    alert({
                      message: "Audience created successfully!",
                      type: "success",
                    })
                  );
                  navigate(pages.myCampaign.route);
                }
              } else {
                setAudienceCreated(res.data.audience_id);
                campaignFormikProps.setValues((prev) => ({
                  ...prev,
                  audienceID: res.data.audience_id,
                }));
                handleClose();
              }

              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          fields={fields}
          edit={edit}
          sliderValue={sliderValue}
          setEdit={setEdit}
          setSliderValue={setSliderValue}
          categories={categories}
          location={location}
          languages={languages}
          handleClose={handleClose}
          component={component}
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
          campaignID={campaignID}
          dialogDetails={dialogDetails}
          setAudienceCreated={setAudienceCreated}
          campaignFormikProps={campaignFormikProps}
          percentageSliderValue={
            typeof percentageSliderValue !== "undefined"
              ? percentageSliderValue
              : percentageSliderValueDialog
          }
          setPercentageSliderValue={
            typeof percentageSliderValue !== "undefined"
              ? setPercentageSliderValue
              : setPercentageSliderValueDialog
          }
        />
      )}
    </Formik>
  );
}
