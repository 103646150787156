import queryString from "query-string";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Loader } from "../../components";
import Form from "./onboarding details components/Form";
import { useAxios, useComponent } from "../../hooks";
import {
  objectFromResponse,
  onboardingObject,
} from "./onboarding details components/onboardingObjects";

export default function DpOnBoardingDetails() {
  const axios = useAxios();
  const { loader } = useComponent();
  const { connectionID } = useParams();
  const brandID = queryString.parse(window.location.search).brandID;

  const [brands, setBrands] = useState([]);
  const [fields, setFields] = useState([]);
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(onboardingObject);

  useEffect(() => {
    if (connectionID) {
      setEditState(false);
    }

    loader.start(3);

    axios({
      url: "/user/getOnboardingFields",
    }).then((field) => {
      if (field.status) {
        setFields(field.data);
        if (connectionID) {
          axios({
            url: "/user/getOnboardBrandMappedDetails?brandId=" + brandID,
          }).then((response) => {
            if (response.status) {
              setFormData(objectFromResponse(response.data[0], field.data));
              loader.apiComplete();
            }
          });
        } else {
          loader.apiComplete();
        }
        loader.apiComplete();
      }
    });

    axios({
      url: "/getAllOnboardingBrands",
    }).then((response) => {
      if (response.status) {
        setBrands(response.data);
        loader.apiComplete();
      } else {
        loader.stop();
      }
    });
  }, [axios, loader, connectionID, brandID]);

  return (
    <Loader>
      <Form
        fields={fields}
        brands={brands}
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
